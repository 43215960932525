import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import {
  AvatarComponent,
  ControlFormFieldDirective,
  DougsDatePipe,
  DragNDropDirective,
  FilePillComponent,
  MemoComponent,
  PillComponent,
  SalesInvoicePillComponent,
  SourceDocumentPillComponent,
  TooltipDirective,
  TrackByPipe,
  VendorInvoicePillComponent,
} from '@dougs/ds';
import { Breakdown, Category, Operation, OperationCategorizationHelpStatus } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { CategoryComponent } from '../../../modals/category-modal/category.component';
import { LengthWordPipe } from '../../../pipes/length-word.pipe';
import { HandleSlotActionComponentService } from '../../../services/handle-slot-action.component.service';
import { OperationActionsComponentService } from '../../../services/operation-actions.component.service';
import { OperationMetricsService } from '../../../services/operation-metrics.service';
import { OperationQueueService } from '../../../services/operation-queue.service';
import { OperationComponentService } from '../../../services/operation.component.service';
import { OperationActionsComponent } from './operation-actions/operation-actions.component';
import { OperationAmountComponent } from './operation-amount/operation-amount.component';
import { OperationAssociationsComponent } from './operation-associations/operation-associations.component';
import { OperationDateComponent } from './operation-date/operation-date.component';
import { OperationDetailsComponent } from './operation-details/operation-details.component';
import { OperationMessageComponent } from './operation-message/operation-message.component';
import { OperationPillsComponent } from './operation-pills/operation-pills.component';
import { OperationReceiptMatchingComponent } from './operation-receipt-matching/operation-receipt-matching.component';

@Component({
  selector: 'dougs-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', visibility: 'hidden', opacity: 0 }),
        animate('200ms ease-in', style({ height: '*', visibility: 'visible', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', visibility: 'visible', opacity: 0.6 }),
        animate('200ms ease-in', style({ height: '0', visibility: 'hidden', opacity: 0 })),
      ]),
    ]),
    trigger('slideInOutOperationDetail', [
      transition(':enter', [
        style({ height: '0', visibility: 'hidden' }),
        animate('200ms ease-in-out', style({ height: '*', visibility: 'visible' })),
      ]),
      transition(':leave', [
        style({ height: '*', visibility: 'visible' }),
        animate('200ms ease-in-out', style({ height: '0', visibility: 'hidden' })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    DragNDropDirective,
    AvatarComponent,
    TooltipDirective,
    LengthWordPipe,
    MemoComponent,
    FormsModule,
    CategoryComponent,
    ControlFormFieldDirective,
    FilePillComponent,
    PillComponent,
    TrackByPipe,
    OperationDateComponent,
    OperationPillsComponent,
    OperationAmountComponent,
    OperationActionsComponent,
    OperationAssociationsComponent,
    OperationMessageComponent,
    OperationDetailsComponent,
    OperationReceiptMatchingComponent,
    VendorInvoicePillComponent,
    DougsDatePipe,
    SalesInvoicePillComponent,
    SourceDocumentPillComponent,
  ],
  providers: [
    OperationComponentService,
    OperationQueueService,
    OperationActionsComponentService,
    HandleSlotActionComponentService,
  ],
})
export class OperationComponent {
  public mainBreakdown: Breakdown | null = null;
  public selectedCategory!: Category;
  public additionalWording = '';
  public disabledCategory = false;
  public classColorBorderOperation = 'm-primary-700';
  public enableFileAnimation = false;
  public salesInvoiceSourceDocumentAttachments: SourceDocumentAttachment[] = [];
  public hasInvoiceSourceDocumentAttachments?: boolean;
  @Input() showOperationDetails = false;
  @Input() isCompact = false;
  @Input() mixpanelModalName?: string;
  @Input() changeUrlOnClick = true;
  @Input() canToggleOperationDetails = true;
  @Input() disableEdit = false;
  @Input() disableRefreshById = false;
  @Input() disableReceiptMatching = false;
  @Input() disableCategoryHelp = false;

  @Output() openOperationDetails: EventEmitter<number> = new EventEmitter<number>();
  @Output() emitOperationAction: EventEmitter<boolean> = new EventEmitter();
  @Output() detachSalesInvoiceSourceDocument: EventEmitter<SourceDocumentAttachment> = new EventEmitter();
  @Output() detachVendorInvoiceSourceDocument: EventEmitter<SourceDocumentAttachment> = new EventEmitter();

  @ContentChild(TemplateRef) actionsTemplate!: TemplateRef<HTMLElement>;

  @HostBinding('@slideInOut') slideInOut = true;

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    if (operation) {
      this.operationComponentService.setCurrentOperation(operation);
      this.mainBreakdown = this.operationService.getMainBreakdown(operation);
      this.salesInvoiceSourceDocumentAttachments =
        this.operationService.getSalesInvoiceSourceDocumentAttachments(operation);
      this.hasInvoiceSourceDocumentAttachments = this.operationService.hasInvoiceSourceDocumentAttachments(operation);
    }
    this.classColorBorderOperation = this.operationComponentService.createClassFromAccountId(
      operation,
      this.synchronizedAccounts,
    );
    this.disabledCategory = !this.operationService.isCategoryEditable(operation) || this.disableEdit;
    this.selectedCategory = this.operationService.getSelectedCategory(this.mainBreakdown);
    if (this._operation.isInvoice) {
      this.additionalWording = this.operationService.getMainBreakdown(this._operation)?.wording ?? '';
    }
  }

  private _synchronizedAccounts: SynchronizedAccount[] = [];

  get synchronizedAccounts(): SynchronizedAccount[] {
    return this._synchronizedAccounts;
  }

  @Input()
  set synchronizedAccounts(synchronizedAccounts: SynchronizedAccount[]) {
    this._synchronizedAccounts = synchronizedAccounts;
    this.classColorBorderOperation = this.operationComponentService.createClassFromAccountId(
      this.operation,
      synchronizedAccounts,
    );
  }

  private _isInOperationListModal = false;

  get isInOperationListModal(): boolean {
    return this._isInOperationListModal;
  }

  @Input()
  set isInOperationListModal(isOperationListModal: boolean) {
    this._isInOperationListModal = isOperationListModal;
    this.operationComponentService.isInOperationListModal = isOperationListModal;
  }

  constructor(
    public readonly operationService: OperationService,
    public readonly operationComponentService: OperationComponentService,
    public readonly operationMetricsService: OperationMetricsService,
  ) {}

  async onCategoryChange(category: Category): Promise<void> {
    this.emitOperationAction.emit(true);
    this.operationComponentService.updateCategory(this.mainBreakdown, category);
    this.operationMetricsService.trackCategoryChange(this.isInOperationListModal, this.mixpanelModalName);
  }

  onOpenOperationDetails(): void {
    this.enableFileAnimation = false;
    this.showOperationDetails = !this.showOperationDetails;
    if (this.changeUrlOnClick) {
      this.openOperationDetails.emit(this.operation.id);
      this.operationComponentService.handleUrlChange(this.showOperationDetails);
    }
  }

  async onDetachSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): Promise<void> {
    const hasBeenDetached: boolean =
      await this.operationComponentService.detachSourceDocumentAttachment(sourceDocumentAttachment);
    if (hasBeenDetached) {
      if (sourceDocumentAttachment.sourceDocument.type === SourceDocumentType.SALES_INVOICE) {
        this.detachSalesInvoiceSourceDocument.emit(sourceDocumentAttachment);
      } else if (sourceDocumentAttachment.sourceDocument.type === SourceDocumentType.VENDOR_INVOICE) {
        this.detachVendorInvoiceSourceDocument.emit(sourceDocumentAttachment);
      }
    }
  }

  async onFileDropped(files: FileList): Promise<void> {
    this.enableFileAnimation = true;
    await this.operationComponentService.uploadFiles(files);
    this.operationMetricsService.trackFilesUpload(this.isInOperationListModal, this.mixpanelModalName);
  }

  protected readonly OperationCategorizationHelpStatus = OperationCategorizationHelpStatus;
}
